import { motion } from 'framer-motion';
import React from 'react';

export default function Modal(props) {
  const { open, children, onClose, layoutId, className = '', style, ...rest } = props;

  if (!open) return null;

  return (
    <div>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        className="modal-container"
        {...rest}
      >
        <motion.section style={style} className={`${className} modal`} layoutId={layoutId}>
          {children}
        </motion.section>
      </motion.div>
    </div>
  );
}

Modal.Content = props => <section className="content" {...props} />;
Modal.Actions = props => <section className="actions" {...props} />;
