import { motion } from 'framer-motion';
import DateTimePicker from 'react-datetime-picker';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useUniqueId } from '../app/utils';

export default function Form(props) {
  return <form {...props} />;
}

Form.Input = props => {
  let { label, error, inputRef, layoutId, children, ...rest } = props;
  children = children || <motion.input ref={inputRef} {...rest} />;

  return (
    <motion.div layoutId={layoutId} layout className="form-field" data-error={error?.type}>
      {label && <label>{label}</label>}
      {children}
    </motion.div>
  );
};

Form.Dropdown = props => {
  const { label, inputRef, options, layoutId, ...rest } = props;

  return (
    <motion.div layoutId={layoutId} layout className="form-field">
      {label && <motion.label>{label}</motion.label>}
      <motion.select {...rest} ref={inputRef}>
        {options.map((option, i) => (
          <motion.option key={i} value={option.value}>
            {option.text}
          </motion.option>
        ))}
      </motion.select>
    </motion.div>
  );
};

Form.TextArea = props => {
  const { label, inputRef, layoutId, ...rest } = props;

  return (
    <motion.div layoutId={layoutId} layout className="form-field">
      {label && <label>{label}</label>}
      <motion.textarea ref={inputRef} {...rest} />
    </motion.div>
  );
};

Form.Password = props => <Form.Input type="password" {...props} />;

Form.Checkbox = props => {
  let { label, error, inputRef, layoutId, children, ...rest } = props;
  const id = useUniqueId('checkbox');
  children = children || <motion.input id={id} ref={inputRef} type="checkbox" {...rest} />;

  return (
    <motion.div
      
      layoutId={layoutId}
      style={{ display: 'flex' }}
      layout
      className="form-field"
      data-error={error?.type}
    >
      {children}
      {label && (
        <label htmlFor={id} style={{ marginLeft: '0.5rem', userSelect: 'none' }}>
          {label}
        </label>
      )}
    </motion.div>
  );
};

Form.DateTime = ({ label, layoutId = undefined, ...rest }) => (
  <Controller
    {...rest}
    render={({ onChange, value }) => (
      <Form.Input
        label={label}
        layoutId={layoutId}
        children={
          <DateTimePicker
            value={value ? new Date(value) : value}
            onChange={date => onChange(date ? date.toISOString() : null)}
          />
        }
      />
    )}
  />
);
